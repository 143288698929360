export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CHANGE_QUANTITY = 'CHANGE_QUANTITY';
export const EMPTY_CART = 'EMPTY_CART';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_FAIL = 'PAYMENT_FAIL';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
